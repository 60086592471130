import React from "react";
import { Link } from "gatsby";
import { useIntl } from "react-intl";
import { defaultLanguage } from "../../i18n";

const PostItem = ({ postContent, category }) => {
  const intl = useIntl();
  const locale =
    defaultLanguage === intl.locale
      ? `${process.env.GATSBY_DEFAULT_LANG}`
      : `${intl.locale}`;

  let thumbnail;
  let alt;
  if (
    postContent.featuredImage !== null &&
    postContent.featuredImage.node !== null
  ) {
    alt = postContent.featuredImage?.node.slug;
    let filteredSizes =
      postContent.featuredImage?.node.mediaDetails.filteredSizes;
    if (filteredSizes.length > 0) {
      let getThumbnail = filteredSizes.filter(
        (img) => img.name === "td_741x486"
      );
      thumbnail = getThumbnail[0]?.sourceUrl;
    } else {
      thumbnail = postContent.featuredImage?.node.mediaItemUrl;
    }
  } else {
    thumbnail = "thumb";
    alt = "alt";
  }

  let postLink;
  if (process.env.GATSBY_MULTILINGUAL === 'true') {
    postLink = `/${locale}/${postContent.slug}/`;
  } else {
    postLink = `/${postContent.slug}/`;
  }

  let postTitle =
    postContent.title.length > 70
      ? `${postContent.title.slice(0, 70 - 1)} ...`
      : postContent.title;

  return (
    <div className="post-item">
      <Link to={postLink} className="post-item__link">
        <img className="post-item__image" loading="lazy" src={thumbnail} alt={alt} />
        <div className="post-item__text-container">
          {category && <div className="post-item__category">{category}</div>}
          <div className="post-item__title">{postTitle}</div>
        </div>
      </Link>
    </div>
  );
};

export default PostItem;
