import React from 'react';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SeoStructure = ({ children, title, description, slug }) => {

  const data = useStaticQuery(graphql`
    query {
      site {
        siteMetadata {
          title
          description
          siteUrl
        }
        id
      }
      wp {
        seo {
          social {
            twitter {
              cardType
              username
            }
          }
        }
        generalSettings {
          description
          title
        }
      }
    }
  `);



  const seoUrl = `${data.site.siteMetadata.siteUrl}${slug === '/' ? '' : '/' + slug}`;
  const twitter = data.wp.seo.social.twitter;
  const wpGeneralSettings = data.wp.generalSettings;

  return (
    <>
      <Helmet
          htmlAttributes={{ lang: `en` }}
          defaultTitle={`Home | ${wpGeneralSettings?.title}`}
          titleTemplate={`${title} | ${wpGeneralSettings?.title}`}
      >
        <title>{title}</title>
        <meta name='description'  content={description || wpGeneralSettings?.description} />
        <link rel='canonical' href={seoUrl} />
        <meta name='og:title' content={title} />
        <meta name='og:description' content={description || data.site.siteMetadata.description} /> 
        <meta name='og:type' content='website' />
        <meta name='og:url' content={seoUrl} />
       <meta name='og:site_name' content={wpGeneralSettings?.title} />
        {twitter.cardType &&
          <meta name='twitter:card' content={twitter.cardType} />
        }
        {twitter.username && 
          <meta name='twitter:site' content={twitter.username} />
        }
      </Helmet>
      {children}
      </>
  );
};

SeoStructure.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  slug: PropTypes.string,
  children: PropTypes.node,
};

export default SeoStructure;